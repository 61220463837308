import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import LongLoading from '../components/shared/LongLoading';

const Home = React.lazy(() => import("../components/Home"));
const LoginForm = React.lazy(() => import("../components/LoginForm"));
const Logout = React.lazy(() => import("../components/authentication/Logout"));
const PasswordForgotten = React.lazy(() => import("../components/authentication/PasswordForgotten"));
const PasswordReset = React.lazy(() => import("../components/authentication/PasswordReset"));
const ViewerRegistrationForm = React.lazy(() => import("../components/authentication/ViewerRegistrationForm"));
const Dashboard = React.lazy(() => import("../components/Dashboard"));
const Videos = React.lazy(() => import("../components/videos/Videos"));
const Audience = React.lazy(() => import("../components/audience/Audience"));
const Video = React.lazy(() => import("../components/videos/Video"));
const VideoAudience = React.lazy(() => import("../components/videos/VideoAudience"));
const EditVideo = React.lazy(() => import("../components/videos/EditVideo"));
const NewVideo = React.lazy(() => import("../components/videos/NewVideo"));
const Tags = React.lazy(() => import("../components/tags/Tags"));
const ViewerVideos = React.lazy(() => import("../components/viewer_videos/ViewerVideos"));
const ViewerVideo = React.lazy(() => import("../components/viewer_videos/ViewerVideo"));
const NotFound = React.lazy(() => import("../components/NotFound"));
const Layout = React.lazy(() => import("../components/layout/Layout"));


function RouteWithLayout({
  component: Component,
  ...rest
}) {
  return (
    <Route {...rest} render={(props) =>
      <Layout>
        <Component {...props} />
      </Layout>
    } />
  );
}

export default (
  <Router>
    <Suspense fallback={<LongLoading />}>
      <Switch>
        <Route path="/" exact component={Home} />
        <RouteWithLayout path="/audience" exact component={Audience} />
        <RouteWithLayout path="/dashboard" exact component={Dashboard} />
        <RouteWithLayout path="/tags" exact component={Tags} />
        <RouteWithLayout path="/videos" exact component={Videos} />
        <RouteWithLayout path="/videos/:id/show" exact component={Video} />
        <RouteWithLayout path="/videos/:id/audience" exact component={VideoAudience} />
        <RouteWithLayout path="/videos/:id" exact component={EditVideo} />
        <RouteWithLayout path="/video" exact component={NewVideo} />
        <RouteWithLayout path="/viewer/videos" exact component={ViewerVideos} />
        <RouteWithLayout path="/viewer/videos/:id" exact component={ViewerVideo} />

        <Route path="/login" exact component={LoginForm} />
        <Route path="/logout" exact component={Logout} />
        <Route path="/signup/viewer" exact component={ViewerRegistrationForm} />
        <Route path="/password/forgotten" exact component={PasswordForgotten} />
        <Route path="/password/reset" exact component={PasswordReset} />

        <Route component={NotFound} />
      </Switch>
    </Suspense>
  </Router>
);