import React from 'react';
import I18n from "i18n-js";
import { Spinner } from 'react-bootstrap';

class LongLoading extends React.Component {
	render () {
		console.log("Render LongLoading with locale " + I18n.locale);

		const loadingVideo = this.props.isVideo && (
			<div className="row">
				<Spinner 
					as="span"
					animation="border"
					role="status"
					size="ms"
					aria-hidden="true" />
					<span className="ml-3">{ I18n.t('loading-video') }</span>
			</div>
		);

		const loadingPage = (
			<h3 className="font-weight-normal">
				<Spinner 
					as="span"
					animation="border"
					role="status"
					aria-hidden="true" />
				<span className="ml-3">{ I18n.t('loading') }</span>
			</h3>
		);

		return (
			<div className="py-3 mt-5">
				<div className="row">
					<div className="col d-flex justify-content-center">
						{ this.props.isVideo ? loadingVideo : loadingPage }
					</div>
				</div>
			</div>
		);
	}
}

export default LongLoading;